(function ($) {
    function checkScroll(el) {
        if (el.find('.menu').scrollLeft() < 2) {
            el.find('.container').addClass('overflowing-right');
            el.find('.container').removeClass('overflowing');
        } else {
            el.find('.container').removeClass('overflowing-right');
            el.find('.container').addClass('overflowing');
        }
    }

    function checkOverflow() {
        $('.component-row').find('.menu-container').each(function () {
            var el = $(this);
            var w = 0;

            el.find('li').each(function () {
                var li = $(this);
                w += li.outerWidth() + 2;
            });

            if (el.outerWidth() < w) {
                checkScroll(el);
            } else {
                el.find('.container').removeClass('overflowing');
                el.find('.container').removeClass('overflowing-right');
            }

            el.find('.menu').scroll(function () {
                checkScroll(el);
            });

        });
    }

    checkOverflow();

    function findColumnClass (c) {
        if (c.indexOf('col-') === 0) {
            return c;
        }
    }

    function invertColumns() {
        var wrappers = $('.invert-columns');
        wrappers.each(function () {
            var $row = $(this).find('.row').first();
            var $firstCol = $row.find('.component-column').first();
            var $secondCol = $firstCol.next();

            $firstCol.before($secondCol);

            var fC = $firstCol.attr('class').split(' ').find(findColumnClass).split('-');
            var sC = $secondCol.attr('class').split(' ').find(findColumnClass).split('-');

            $firstCol.addClass('col-' + sC[1] + '-pull-' + sC[2]);
            $secondCol.addClass('col-' + fC[1] + '-push-' + fC[2]);
        });
    }

    invertColumns();

    $(window).resize(function () {
        checkOverflow();
    });
}(jQuery));
