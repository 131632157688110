Vue.component('inspiration-trip-builder', {
    template: '#inspiration-trip-builder-template',
    props: [
    ],
    data: function () {
        return {
            active: false,
            categories: {},
            categoriesNav: [], // Works like a breadcrumb, holds all categories that's been walked through
            categoriesChosen: [],
            categoriesNotifications: {},
            isLoading: true,
            isComplete: false,
            showBuilder: false,
            showSubmit: false,
            showSubmitIsMobile: false,
            showSubmitAfterLoad: false,
            showMobileNavigation: false,
            showShareURL: false,
            showURLBox: false,
            shareURL: '',
            completeURL: '',
            shareURLText: 'Matchande resmål',
            generatingShareURL: false,
            loadingAnimation: null,
            choicesTransitionTrigger: 1,
            sideTitle: 'Skapa din drömresa',
            mobileIsMain: true,
            mainContentTabText: 'Välj önskemål',
            submitHasErrors: false,
            submitErrorText: '',
            submitLoading: false,
            submitFieldsForStorage: {}
        };
    },
    watch: {
        categories: function () {
            if ($.isEmptyObject(this.categories)) {
                this.isLoading = true;
            } else {
                this.isLoading = false;
            }

            for (var index in this.categories.choices) {
                var choice = this.categories.choices[index];
                var choiceIndex = choice.type + '_' + choice.type_id;

                var kIndex = this.categoriesChosen.map(function(c) {
                    return c.animationKey;
                }).indexOf(choiceIndex);

                if (kIndex > -1) {
                    this.categories.choices[index].chosen = true;
                } else {
                    this.categories.choices[index].chosen = false;
                }
            }

            this.choicesTransitionTrigger = Math.floor(Math.random() * 10000) + 1;
            this.builderScrollTop();
        },
        categoriesChosen: function () {

            /* START SPECIAL: IF a crash/bugg would happen. Don't allow multiple keys. */
            var animationKeys = [];

            for (var i = 0; i < this.categoriesChosen.length; i++) {
                var c = this.categoriesChosen[i];
                if (animationKeys.indexOf(c.animationKey) === -1) {
                    animationKeys.push(c.animationKey);
                } else {
                    this.categoriesChosen.splice(i, 1);
                }
            }
            /* END SPECIAL */

            localStorage.setItem('categories-chosen', JSON.stringify(this.categoriesChosen));

            if (this.categoriesChosen.length === 0) {
                this.sideTitle = 'Skapa din drömresa';
            } else {
                this.sideTitle = 'Mina önskemål';
            }

            this.countAndSetNotifications();

            window.CohEvent.fire('choicesUpdated', this.categoriesChosen);
        },
        mobileIsMain: function () {
            if (!this.mobileIsMain) {
                $('.builder__main').removeClass('mobile-show');
                $('.builder__main').addClass('mobile-hide');

                setTimeout(function () {
                    $('.builder__side').show();
                    $('.builder__side').removeClass('mobile-hide');
                    $('.builder__side').addClass('mobile-show');
                    $('.builder__mobile-navigation').addClass('show-sidebar');
                    $('.builder__main').hide();
                }, 400);
            } else {
                $('.builder__side').removeClass('mobile-show');
                $('.builder__side').addClass('mobile-hide');
                $('.builder__mobile-navigation').removeClass('show-sidebar');

                setTimeout(function () {
                    $('.builder__main').show();
                    $('.builder__main').removeClass('mobile-hide');
                    $('.builder__main').addClass('mobile-show');
                    $('.builder__side').hide();
                }, 400);
            }
        },
        shareURL: function () {
            /*if(this.shareURL === '') {
                this.shareURLText = 'Skapa resa';
            } else {
                this.shareURLText = 'Visa resa';
            }*/

            this.shareURLText = 'Matchande resmål';
        },
        isLoading: function () {
            var builder = this;
            if (this.isLoading === false && this.showSubmitAfterLoad === true) {
                this.showSubmitAfterLoad = false;
                Vue.nextTick(function () {
                    setTimeout(function () {
                        builder.showSubmitView();
                    }, 500);
                });
            }
        }
    },
    methods: {
        initData: function () {
            var builder = this;

            $.post({
                url: window.urls.ajaxurl,
                data: {
                    action: 'hausGetBuilderCategories'
                },
                success: function (response) {
                    if(response.success === true) {
                        var ajaxData = response.data;

                        builder.categories = ajaxData;
                        builder.categoriesNav.push(builder.categories);
                    }
                }
            });
        },
        loadingDone: function () {
          this.showBuilder = true;
          this.showMobileNavigation = true;
        },
        toggleBuilder: function () {
            this.active = !this.active;
        },
        chooseCategory: function (index, isParent) {
            if(isParent) {
                this.previousCategories = this.categories;
                this.categories = this.categories.choices[index];
                this.categoriesNav.push(this.categories);
                window.CohEvent.fire('pushURL', this.categories.title);
            }
        },
        countAndSetNotifications: function () {
            this.categoriesNotifications = {};

            var builder = this;

            this.categoriesChosen.forEach(function (choice) {
                var parents = choice.parent_id.toString().split('_');
                parents.forEach(function (id) {
                    if(builder.categoriesNotifications[id] === undefined) {
                        builder.categoriesNotifications[id] = 1;
                    } else {
                        builder.categoriesNotifications[id]++;
                    }
                });
            });
        },
        toggleCategory: function (index) {
            var choice = this.categories.choices[index];
            var choiceIndex = choice.type + '_' + choice.type_id;
            var kIndex = this.categoriesChosen.map(function(c) {
                return c.animationKey;
            }).indexOf(choiceIndex);

            choice.chosen = !choice.chosen;

            if(choice.chosen) {
                // If not already added
                if(kIndex === -1) {
                    // Add key for animation and for indexing
                    choice.animationKey = choiceIndex;

                    this.categoriesChosen.push(choice);
                    this.sideScrollTop();
                }
            } else {
                // Find the index with help of the animationKey
                this.categoriesChosen.splice(kIndex, 1);
            }

            this.hideURLBox();
        },
        addChoice: function (choice) {
            this.categoriesChosen.push(choice);

            this.hideURLBox();
        },
        removeChoice: function (choice) {
            var index = this.categoriesChosen.indexOf(choice);
            var builder = this;

            // When choice comes from a different source it may not be the same
            // So find the correct choice to remove by comparing the animationKeys
            if(index === -1) {
                var someResult = this.categoriesChosen.some(function (c) {
                    if(choice.animationKey === c.animationKey) {
                        index = builder.categoriesChosen.indexOf(c);
                        return true;
                    }
                });
                if(!someResult) {
                    return false;
                }
            }

            if (this.categories.choices !== undefined) {
                // START SPECIAL: Fix for not updating correctly when loaded from localStorage
                var categoryIndex = null;
                var typeId = choice.animationKey.split('_');
                typeId = parseInt(typeId[typeId.length - 1]);
                var categoryIndexResult = this.categories.choices.some(function(c) {
                    if(typeId === c.type_id) {
                        categoryIndex = builder.categories.choices.indexOf(c);
                        return true;
                    }
                });

                if(categoryIndexResult) {
                    this.categories.choices[categoryIndex].chosen = false;
                } else {
                    choice.chosen = false;
                }
                // END SPECIAL
            }

            this.categoriesChosen.splice(index, 1);

            this.hideURLBox();
        },
        previousCategory: function (backButton) {
            if(!this.showSubmit) {
                this.categoriesNav.pop();
                this.categories = this.categoriesNav[this.categoriesNav.length - 1];
            } else {
                this.toggleSubmit();
            }

            if(!backButton) {
                window.CohEvent.fire('previousURL');
            }
        },
        showSidebar: function (updateURL) {
            if(this.mobileIsMain) {
                this.mobileIsMain = false;

                if(updateURL) {
                    window.CohEvent.fire('pushURL', 'mina-onskemal');
                }
            }
        },
        hideSidebar: function (updateURL) {
            if(!this.mobileIsMain) {
                this.mobileIsMain = true;

                if(updateURL) {
                    window.CohEvent.fire('previousURL');
                }
            }
        },
        showSubmitView: function () {
            if (!this.showSubmit) {
                if (!this.isLoading) {
                    this.toggleSubmit();
                } else {
                    this.showSubmitAfterLoad = true;
                }
            }
        },
        toggleSubmit: function () {
            // Don't allow toggle if still loading
            if (!this.isLoading && this.categoriesChosen.length > 0) {

                var builder = this;

                // Had to split up to an if else with timeout, so animations would work properly
                // Possible that there is a better solution out there..
                if (this.showBuilder) {
                    this.showBuilder = !this.showBuilder;

                    if(this.mobileIsMain) {
                        setTimeout(function () {
                            builder.showSubmit = !builder.showSubmit;
                            Vue.nextTick(function () {
                                $('.datepicker').pickadate({
                                    format: 'dd-mm-yyyy'
                                });
                                $('#gform_submit_button_4').click(builder.submitForm);
                                builder.handleSubmitForm();
                            });
                        }, 400);
                        builder.showSubmitIsMobile = false;
                    } else {
                        this.hideSidebar(false);
                        builder.showSubmit = !builder.showSubmit;
                        Vue.nextTick(function () {
                            $('.datepicker').pickadate({
                                format: 'dd-mm-yyyy'
                            });
                            $('#gform_submit_button_4').click(builder.submitForm);
                            builder.handleSubmitForm();
                        });
                        builder.showSubmitIsMobile = true;
                    }

                    window.CohEvent.fire('pushURL', 'begar-offert');
                    this.builderScrollTop();
                } else {
                    this.showSubmit = !this.showSubmit;
                    if(builder.showSubmitIsMobile) {
                        builder.showSidebar(false);
                    }
                    setTimeout(function () {
                        builder.showBuilder = !builder.showBuilder;
                    }, 400);
                }
            }
        },
        hideSubmit: function () {
            var builder = this;

            this.showSubmit = false;
            setTimeout(function () {
                builder.showBuilder = true;
            }, 400);
            window.CohEvent.fire('previousURL');
        },
        handleSubmitForm: function () {
            // Since the form gets removed and re-rendered and we have no modals for
            // the form, since it's a gravity form. We need to handle save and load of user input.
            var builder = this;

            $('#gform_4').closest('form').find('.gfield').each(function () {
                var $input = $(this).find('input, select, textarea');
                var name = $input.attr('name');

                // Set the field from saved value
                if(builder.submitFieldsForStorage[name] !== undefined) {
                    if ($input.attr('type') !== 'checkbox') {
                        $input.val(builder.submitFieldsForStorage[name]);
                    } else {
                        $input.attr('checked', true);
                    }
                }

                $input.on('change', function () {
                    if ($(this).attr('type') !== 'checkbox') {
                        builder.submitFieldsForStorage[name] = $(this).val();
                    } else {
                        if ($(this).is(':checked')) {
                            builder.submitFieldsForStorage[name] = true;
                        } else {
                            delete builder.submitFieldsForStorage[name];
                        }
                    }
                    localStorage.setItem('form-fields', JSON.stringify(builder.submitFieldsForStorage));
                });
            });
        },
        submitForm: function (e) {
            e.preventDefault();

            var builder = this;

            this.submitHasErrors = false;
            this.submitErrorText = '';
            var fields = [];
            var email = $('#gform_4').find('.ginput_container_email input').val();

            // Go through all the fields
            $('#gform_4').closest('form').find('.gfield').each(function () {
                var $input = $(this).find('input, select, textarea');
                var name = $input.attr('name');
                var val = $input.val();
                var required = $(this).hasClass('gfield_contains_required');

                // If input is checkbox that is not checked, do nothing
                if (!($input.attr('type') === 'checkbox' && !$input.is(':checked'))) {
                    if (required && (val === undefined || val.trim() === '' || val === null)) {
                        $input.addClass('error');
                        builder.submitHasErrors = true;
                    } else {
                        $input.removeClass('error');
                        fields.push({field: name, value: val});
                    }
                }
            });

            $('.error').unbind('change').on('change', function () {
                $(this).removeClass('error');
            });

            if (!this.submitHasErrors) {
                this.submitLoading = true;

                Vue.nextTick(function () {
                    $('.builder__main, .builder').animate({
                        scrollTop: $('.builder__main').height()
                    }, 400);
                });

                if(this.shareURL === '') {
                    this.generateShareURL(false, false, function () {return builder.submitForm(e);});
                    return false;
                }

                $.post({
                    url: window.urls.ajaxurl,
                    data: {
                        action: 'hausSendOfferForm',
                        fields: fields,
                        email: email,
                        id: builder.shareURL
                    },
                    success: function (response) {
                        builder.submitLoading = false;

                        if(response.success === true) {
                            var ajaxData = response.data;

                            builder.completeURL = builder.shareURL;

                            builder.categoriesChosen = [];

                            builder.shareURL = '';
                            localStorage.setItem('share-url', builder.shareURL);

                            builder.showShareURL = false;
                            builder.showURLBox = false;

                            builder.submitFieldsForStorage = {};
                            localStorage.setItem('form-fields', JSON.stringify(builder.submitFieldsForStorage));

                            builder.showMobileNavigation = false;
                            builder.showSubmit = false;
                            builder.isComplete = true;
                        } else {
                            builder.submitHasErrors = true;
                            builder.submitErrorText = response.data.text;

                            if (response.data.text_fields !== undefined) {
                                var fields = response.data.text_fields;
                                builder.submitErrorText += '<br />';
                                for (var key in fields) {
                                    builder.submitErrorText += '<br /> ' +
                                        $('#field_4_'+key+' label').text() +
                                        ': <span style="text-transform: none;">' + fields[key] + '</span>';
                                }
                            }
                        }
                    }
                });
            } else {
                this.submitLoading = false;
                this.submitErrorText = 'Vänligen fyll i alla obligatoriska fält.';
                Vue.nextTick(function () {
                    $('.builder__main, .builder').animate({
                        scrollTop: $('.builder__main').height()
                    }, 400);
                });
            }

        },
        generateShareURL: function (openURL, showBox, callback) {
            if (this.isLoading || this.categoriesChosen.length === 0) {
                return false;
            }

            if ((!this.showShareURL && this.shareURL === '') || (!openURL && !this.showShareURL)) {
                this.showShareURL = true;

                // If there already is a shareURL from LocalStorage
                if (this.shareURL !== '') {
                    if (showBox) {
                        this.showURLBox = true;
                    }
                } else { // Otherwise generate new URL
                    this.generatingShareURL = true;

                    var tempCategoriesChosen = [];
                    for(var i = 0; i < this.categoriesChosen.length; i++) {
                        tempCategoriesChosen[i] = {
                            type: this.categoriesChosen[i].type,
                            type_id: this.categoriesChosen[i].type_id
                        };
                    }

                    var builder = this;

                    $.post({
                        url: window.urls.ajaxurl,
                        data: {
                            action: 'hausGetShareURL',
                            categories: tempCategoriesChosen
                        },
                        success: function (response) {
                            if(response.success === true) {
                                var ajaxData = response.data;

                                builder.shareURL = ajaxData.url;
                                localStorage.setItem('share-url', builder.shareURL);

                                if (!openURL) {
                                    builder.generatingShareURL = false;
                                    setTimeout(function () {
                                        if (showBox) {
                                            builder.showURLBox = true;
                                        }
                                    }, 400);
                                } else {
                                    Vue.nextTick(function () {
                                        window.location.replace($(builder.$refs.goURL).data('url').trim());
                                    });
                                }

                                if (callback !== null && callback !== undefined) {
                                    callback();
                                }
                            }
                        }
                    });
                }
            } else if (openURL) {
                window.location.replace($(this.$refs.goURL).data('url').trim());
            }
        },
        showTrip: function () {
            window.location.replace($(this.$refs.completeURL).data('url').trim());
        },
        completeAndRestart: function () {
            var builder = this;

            this.showMobileNavigation = true;
            this.isComplete = false;
            this.showSubmit = true;

            // Go back from submit
            this.previousCategory(false);

            // Hide sidebar if mobile
            this.hideSidebar(false);

            // Go back to start
            builder.categories = builder.categoriesNav[0];
            builder.categoriesNav = [builder.categories];
            window.CohEvent.fire('goToStart');
        },
        hideURLBox: function () {
            this.showURLBox = false;
            this.generatingShareURL = false;
            this.showShareURL = false;
            this.shareURL = '';
            localStorage.removeItem('share-url');
        },
        builderScrollTop: function () {
            $('#inspiration-trip-builder-master .builder').animate({ scrollTop: 0 });
            $('#inspiration-trip-builder-master .builder__main').animate({ scrollTop: 0 });
        },
        sideScrollTop: function () {
            $('#inspiration-trip-builder-master .builder__side .choices').animate({ scrollTop: 0 });
        },
        selectDivText: function (event) {
            window.getSelection().selectAllChildren(event.target);
        }
    },
    mounted: function () {
    },
    created: function () {
        window.CohEvent.listen('toggleBuilder', this.toggleBuilder);
        window.CohEvent.listen('builderGoBack', this.previousCategory);
        window.CohEvent.listen('builderHideSidebar', this.hideSidebar);
        window.CohEvent.listen('addChoice', this.addChoice);
        window.CohEvent.listen('removeChoice', this.removeChoice);
        window.CohEvent.listen('showSubmitView', this.showSubmitView);

        var chosen = JSON.parse(localStorage.getItem('categories-chosen'));

        if (Array.isArray(chosen)) {
            this.categoriesChosen = chosen;
        }

        window.CohEvent.fire('choicesUpdated', this.categoriesChosen);

        var share = localStorage.getItem('share-url');

        // Remember the shareURL
        if ((typeof share === 'string' || share instanceof String) && share.trim() !== '') {
            this.shareURL = share;
        }

        var fields = JSON.parse(localStorage.getItem('form-fields'));

        if (fields && fields.constructor === Object) {
            this.submitFieldsForStorage = fields;
        }

        this.initData();
    }
});