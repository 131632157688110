jQuery(document).ready(function ($) {

    $('.component-inspirationtripslider').fadeIn();

    function changeRowBG (slide, slider) {
        slider.allowBgChange = false;
        var $slide = $(slide);
        var bg = $slide.data('bg');

        var $bgImage = $slide.closest('.section').find('.bg-image');

        if ($bgImage.length > 0) {
            $bgClone = $bgImage.clone().insertAfter($bgImage).hide().addClass('gradient-me');
        } else {
            $bgClone = $slide.closest('.section').find('.row-wrapper')
                        .prepend('<div class="bg-image lazyload gradient-me" data-bgset="'+bg+'"></div>')
                        .hide();
        }

        // Remove old picture
        $bgClone.find('picture').remove();

        $bgClone.attr('data-bgset', bg).fadeIn(600, function () {
            setTimeout(function () {
                $bgImage.remove();
                slider.allowBgChange = true;

                if(slider.bgStack !== null) {
                    changeRowBG(slider.bgStack, slider);
                    slider.bgStack = null;
                }
            }, 300);
        });
    }

    $('.inspiration-trip-slider').each(function () {
        $(this).closest('.section').addClass('has-slider');

        var slider = new TinSlide(this, {
            generate: {
                dots: {
                    on: true
                },
                styles: {
                    on: false
                },
                nav: {
                    on: true
                }
            },
            effects: {
                scale: {
                    on: true
                },
                fade: {
                    on: true,
                    minAt: 0.5
                },
                slideHorizontal: {
                    offset: 0
                },
            },
            cropContainer: true
        });

        if($(this).hasClass('row-background')) {
            $(this).parent().find('nav.tin-slide-next-prev').addClass('row-background');

            slider.allowBgChange = true;
            slider.bgStack = null;

            slider.on('itemSelected', function(event) {
                if(slider.allowBgChange) {
                    changeRowBG(event.item, slider);
                    slider.bgStack = null;
                } else {
                    slider.bgStack = event.item;
                }
            });

            changeRowBG(slider.getCurrentItem(), slider);
        }
    });
});
