if ($('#inspiration-trip-post-single-tags').length) {
    var inspirationTripBuilder = new Vue({
        el: '#inspiration-trip-post-single-tags',
        data: function () {
            return {
                choicesLength: 0
            };
        },
        watch: {
            choicesLength: function () {
                if(this.choicesLength === 0) {
                    $('.inspiration-trip-post-single-tags-add').html('Begär reseförslag');
                } else {
                    $('.inspiration-trip-post-single-tags-add').html('Lägg till i önskemål');
                }
            }
        },
        computed: {
        },
        methods: {
            refreshCategories: function (categories) {
                this.choicesLength = categories.length;

                $('.trip-category .slider .element').removeClass('chosen');

                for (var i = 0; i < categories.length; i++) {
                    $('.trip-category .slider[data-animation-key="'+categories[i].animationKey+'"]')
                        .find('.element').addClass('chosen');
                }
            },
            toggleCategory: function(event, title, category, type, type_id, icon, parent_id) {
                $slider = $(event.target).hasClass('slider') ? $(event.target) : $(event.target).closest('.slider');
                $element = $slider.find('.element');

                var choice = {
                    title: title,
                    category: category,
                    type: type,
                    type_id: type_id,
                    animationKey: type + '_' + type_id,
                    icon: icon,
                    chosen: true,
                    parent_id: parent_id
                };

                if (!$element.hasClass('chosen')) {
                    window.CohEvent.fire('addChoice', choice);
                } else {
                    window.CohEvent.fire('removeChoice', choice);
                }

                $element.toggleClass('chosen');
            },
            addAllChoices: function (e) {
                e.preventDefault();

                var builder = this;

                $('#inspiration-trip-post-single-tags').find('.trip-category').each(function () {
                    $choice = $(this);
                    $slider = $choice.find('.slider');
                    $element = $choice.find('.element');

                    if (!$element.hasClass('chosen')) {
                        builder.$refs[$slider.data('animation-key')].click();
                    }
                });

                window.CohEvent.fire('barToggleBuilder', false);
            },
            addListeners: function () {
                $('.inspiration-trip-post-single-tags-add').closest('.btn').click(this.addAllChoices);
            }
        },
        created: function () {
            window.CohEvent.listen('choicesUpdated', this.refreshCategories);

            this.addListeners();
        }
    });
}