jQuery(document).ready(function ($) {
    // $('.mobilemenu-navigation ul ul .has-sub-menu').click(function (e) {
    //     e.stopPropagation();
    //     e.preventDefault();

    //     $(this).toggleClass('show-menu');
    //     $(this).find('.sub-menu').slideToggle();
    // });
    // $('.mobilemenu-navigation ul > .has-sub-menu').click(function (e) {
    //     console.log('b');
    //     e.preventDefault();
    //     $(this).toggleClass('show-menu');
    //     $(this).find('.sub-menu:first').slideToggle();
    // });


    $('.mobilemenu-navigation li.has-sub-menu li.has-sub-menu .icon').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parent().toggleClass('show-menu');
        $(this).parent().find('.sub-menu').first().slideToggle();
    });
    $('.mobilemenu-navigation li.has-sub-menu .icon').click(function(e) {
        if($(this).parent().parent().hasClass('sub-menu')) {
            return;
        }
        e.preventDefault();
        $(this).parent().toggleClass('show-menu');
        $(this).parent().find('.sub-menu').first().slideToggle();
    });

    $('.mobilemenu-navigation li.active.has-sub-menu .icon').click();
});
