jQuery(document).ready(function ($) {
    $('.tin-slide').each(function () {
        new TinSlide(this, {
            generate: {
                dots: {
                    on: false
                },
                styles: {
                    on: false
                }
            },
            effects: {
                scale: {
                  on: true
                },
                fade: {
                  on: true
                }
            },
            cropContainer: true
        });
    });
});
