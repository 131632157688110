jQuery(document).ready(function ($) {
    $('.component-mobilemenu').hide();
    var clickBlock = false;
    $('#toggle-nav').click(function () {
        if(!clickBlock) {
            clickBlock = true;
            setTimeout(function () {
                clickBlock = false;
            }, 350);

            $(this).toggleClass('open');
            $('.site-logo').toggleClass('nav-open');
            $('.component-mobilemenu').toggleClass('show-nav').toggleClass('hide-nav');
            $('.component-header').toggleClass('show-nav');

            if($('.component-mobilemenu').hasClass('hide-nav')) {
                setTimeout(function () {
                    $('.component-mobilemenu').hide();
                }, 400);
            } else {
                $('.component-mobilemenu').show();
            }
        }
    });

    $(window).on('resize', function() {

        if($(window).width() > 992) {
            $('#toggle-nav').removeClass('open');
            $('.site-logo').removeClass('nav-open');
            $('.component-mobilemenu').removeClass('show-nav').addClass('hide-nav');
            $('.component-header').removeClass('show-nav');
            $('.component-mobilemenu').hide();

        }
    });

    $('.sub-menu .icon').click(function() {
        $(this).toggleClass('open');
        $(this).siblings('.sub-menu').toggleClass('open');
    });

    $('.has-sub-menu .sub-menu .active .icon').click();
});
