$(document).ready(function() {

    $('.filter').on('click',function(event) {

        event.preventDefault();

        var filter = $(this).attr('data-filter');

        $('.department:not(.'+filter+')').hide(600, 'swing');
        $('.' + filter).show(600, 'swing');

    });

});
