if ($('#inspiration-trip-builder-master').length) {
    var vueScrollTop = 0; // Stores scrollTop value before active so page does not jump to old value.

    var inspirationTripBuilder = new Vue({
        el: '#inspiration-trip-builder-master',
        router: new VueRouter({
            mode: 'history',
            scrollBehavior: function (to, from, savedPosition) {
                // Functionality seems glitchy, this is working,
                // but I don't know why returning false does not.
                return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                      resolve({ x: 0, y: vueScrollTop });
                    }, 0);
                });
            }
        }),
        data: function () {
            return {
                openedAtLeastOnce: false,
                active: false,
                isUserNav: false,
                isResetNav: false,
                URLSteps: 0
            };
        },
        watch: {
        },
        computed: {
        },
        methods: {
            toggleActive: function (updateURL) {
                vueScrollTop = $(window).scrollTop();

                this.active = !this.active;
                $('body').toggleClass('builder-active');

                this.isUserNav = true;

                if(this.active && !this.openedAtLeastOnce) {
                    this.$router.push({path: 'konfigurator', query: this.$route.query});
                    this.URLSteps = 1;
                } else if(this.active && this.openedAtLeastOnce) {
                    this.$router.go(this.URLSteps);
                } else {
                    if(updateURL) {
                        this.$router.go(-this.URLSteps);
                    }
                }

                this.openedAtLeastOnce = true;
            },
            pushURL: function (url) {
                this.isUserNav = true;
                this.$router.push({path: this.$route.path + '/' + this.cleanUpURL(url), query: this.$route.query});
                this.URLSteps++;
            },
            previousURL: function () {
                this.isUserNav = true;
                this.$router.go(-1);
                this.URLSteps--;
            },
            goToStart: function () {
                if (this.URLSteps > 1) {
                    this.isResetNav = true;
                    this.$router.go(1 - this.URLSteps);
                    this.URLSteps = 1;
                }
            },
            cleanUpURL: function (url) {
                //Change special characters.
                url = url.replace(/[áàäâãåÁÀÄÂÃÅ]/, "a");
                url = url.replace(/[óòöôõÓÒÖÔÕ]/, "o");
                url = url.replace(/[éèëêÉÈËÊ]/, "e");
                url = url.replace(/[úùüûÚÙÜÛ]/, "u");
                url = url.replace(/[íìïîÍÌÏÎ]/, "i");

                //Replace other characters with "_".
                url = url.replace(/[^a-zA-Z0-9]/, "-");

                //Change the string to lower case.
                url = url.toLowerCase();

                return url;
            }
        },
        created: function () {
            window.CohEvent.listen('toggleBuilder', this.toggleActive);
            window.CohEvent.listen('pushURL', this.pushURL);
            window.CohEvent.listen('previousURL', this.previousURL);
            window.CohEvent.listen('goToStart', this.goToStart);

            var builder = this;

            this.$router.afterEach(function (to, from, next) {
                if(to.path !== from.path) {
                    if (to.path.indexOf('konfigurator') > -1) {
                        if (!builder.isUserNav && !builder.isResetNav) {
                            if(from.path.indexOf('/mina-onskemal') > -1 && from.path.indexOf('/mina-onskemal/') === -1) {
                                window.CohEvent.fire('builderHideSidebar', false);
                            } else {
                                window.CohEvent.fire('builderGoBack', true);
                            }
                            builder.URLSteps--;
                        } else if (builder.isUserNav) {
                            builder.isUserNav = false;
                        } else if (builder.isResetNav) {
                            builder.isResetNav = false;
                        }
                    } else {
                        if (builder.URLSteps === 1 && !builder.isUserNav) {
                            window.CohEvent.fire('barToggleBuilder', false);
                        }
                    }
                }
            });
        }
    });
}