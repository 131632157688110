jQuery(document).ready(function ($) {
    $('.offers-filters select').on('change', function () {
        var continent = $('.offers-filters select.continent').val();
        var country = $('.offers-filters select.country').val();
        
        $('.hotels-container .hotel-item').hide();

        var continentData = parseInt(continent) !== 0 ? '[data-continent="'+continent+'"]' : '';
        var countryData = parseInt(country) !== 0 ? '[data-country="'+country+'"]' : '';
        
        $('.hotels-container .hotel-item'+continentData+countryData).show();
    });
});