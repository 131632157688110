Vue.component('inspiration-trip-bar', {
    template: '#inspiration-trip-bar-template',
    props: [
    ],
    data: function () {
        return {
            active: false,
            choicesLength: 0
        };
    },
    computed: {
        text: function () {
            return !this.active && this.choicesLength === 0 ?
                'Skapa din drömresa' :
                'Mina önskemål';
        },
        buttonText: function () {
            return !this.active && this.choicesLength === 0 ?
                'Starta här' :
                'Fortsätt';
        }
    },
    watch: {
        choicesLength: function () {
            if(this.choicesLength === 0) {
                $('.inspiration-trip-hotel-toggle').find('.text').html('Begär reseförslag');
            } else {
                $('.inspiration-trip-hotel-toggle').find('.text').html('Lägg till i önskemål');
            }
        }
    },
    methods: {
        toggleBuilder: function (updateURL) {
            if(this.active) {
                if($('#inspiration-trip-builder-master .builder').scrollTop() > 80) {
                    $('#inspiration-trip-builder-master .builder').animate({ scrollTop: 0 });
                    return;
                }
            }

            this.active = !this.active;
            window.CohEvent.fire('toggleBuilder', updateURL);
        },
        updateChoicesNum: function (choices) {
            this.choicesLength = choices.length;
        },
        toggleHotel: function () {
            var $hotel = $('.inspiration-trip-hotel-toggle');
            var builder = this;

            var choice = {
                title: $hotel.data('title'),
                category: $hotel.data('category'),
                type: $hotel.data('type'),
                type_id: $hotel.data('type-id'),
                parent_id: $hotel.data('parent-id'),
                animationKey: $hotel.data('type') + '_' + $hotel.data('type-id'),
                icon: $hotel.data('icon'),
                chosen: true
            };

            if ($hotel.hasClass('not-added')) {
                window.CohEvent.fire('addChoice', choice);
                window.CohEvent.fire('barToggleBuilder', false);
                Vue.nextTick(function () {
                    window.CohEvent.fire('showSubmitView');
                });
            } else if ($hotel.hasClass('added')) {
                window.CohEvent.fire('removeChoice', choice);
            }
        },
        checkHotel: function (categoriesChosen) {
            var $hotel = $('.inspiration-trip-hotel-toggle');
            var hotelIndex = $hotel.data('type') + '_' + $hotel.data('type-id');
            var builder = this;

            var kIndex = categoriesChosen.map(function(c) {
                return c.animationKey;
            }).indexOf(hotelIndex);

            if (kIndex === -1) {
                $hotel.removeClass('added').addClass('not-added');

                Vue.nextTick(function () {
                    if (builder.choicesLength === 0) {
                        $hotel.find('.text').html('Begär reseförslag');
                    } else {
                        $hotel.find('.text').html('Lägg till i önskemål');
                    }
                });
            } else {
                $hotel.removeClass('not-added').addClass('added');
                Vue.nextTick(function () {
                    $hotel.find('.text').html('Ta bort från önskemål');
                });
            }
        },
        addListeners: function () {
            var builder = this;

            $('.inspiration-trip-hotel-toggle').click(this.toggleHotel);
            $('.inspiration-trip-open-builder').click(function (e) {
                e.preventDefault();
                builder.toggleBuilder();
            });
        }
    },
    mounted: function () {
    },
    created: function () {
        window.CohEvent.listen('choicesUpdated', this.updateChoicesNum);
        window.CohEvent.listen('barToggleBuilder', this.toggleBuilder);
        window.CohEvent.listen('choicesUpdated', this.checkHotel);

        this.addListeners();
    }
});